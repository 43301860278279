import React, { useEffect, useRef, useState } from 'react';
import "./ContactComponent.scss";
import { addBooking, getOpenings } from '../stores/api';
import dayjs from 'dayjs';

const ContactComponent = () => {
    const [openings, setOpenings]           = useState<any[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
    const [formState, setFormState]         = useState<number>(0);
    const [displayError, setDisplayError]   = useState<boolean>(false);
    const formRef                           = useRef(null);
    const fileInput                         = useRef(null);

    useEffect(() => {
        getOpenings().then(setOpenings);
    }, []);

    const onUploadFile = () => {
        if (fileInput?.current) {
            const files = uploadedFiles;

            for (const file of fileInput.current.files) {
                const url = URL.createObjectURL(file);
                files.push({
                    img: file,
                    url: url
                });
            }

            setUploadedFiles([...files]);
        }
    };

    const submit = (event) => {
        setFormState(1);
        event.preventDefault();
        
        if (formRef.current) {
            const formData = new FormData(formRef.current);

            for (let i = 0; i < uploadedFiles.length; i++) {
                const file = uploadedFiles[i];
                formData.append('image' + i, file.img, "image" + i + "-" + file?.img?.name);
            }
            addBooking(formData).then(res => {
                if (res.success) {
                    setFormState(2);
                } else {
                    setFormState(0);
                    setDisplayError(true);
                }
            });
        }

        return false;
    }

    return <div className="contact-container">
        {formState === 0 ? <div className="form-container">
            <form className="form-wrapper" ref={formRef} encType="multipart/form-data">
                {displayError && <span className="error-msg">There was an error</span>}
                <div className="form-item">
                    <label>Full Name: </label>
                    <input type="text" name="name" placeholder="Full Name"/>
                </div>
                <div className="form-item">
                    <label>Pronouns: </label>
                    <input type="text" name="pronouns" placeholder="Pronouns"/>
                </div>
                <div className="form-item">
                    <label>Email Address: </label>
                    <input type="email" name="email" placeholder="E-mail Address"/>
                </div>
                <div className="form-item">
                    <label>Phone Number: </label>
                    <input type="phone" name="phone" placeholder="Phone Number"/>
                </div>
                <div className="form-item">
                    <label>Price Range: </label>
                    <input type="text" name="budget" placeholder="Price Range"/>
                </div>
                <div className="form-item">
                    <label>Tattoo Description: 
                    <div className="sublabel">Include details such as size (in) , placement, and if you’d like color, black/grey or line work</div></label>
                    <textarea name="description" rows={10} placeholder="Tattoo Description"/>
                </div>
                <div className="form-item">
                    <label>Photo References: </label>
                    <input type="file" id="file-upload" accept="image/png, image/jpeg" ref={fileInput} multiple onChange={onUploadFile} hidden/>
                    <label htmlFor="file-upload" className="file-upload-button">Upload Image</label>
                    <br/>
                </div>
                {uploadedFiles.length > 0 && <p>Click on an image to remove it</p>}
                <div className="image-previews">
                    {uploadedFiles.map((img, index) => 
                        <img src={img?.url} alt="" key={index} onClick={() => setUploadedFiles(uploadedFiles.filter((v, i) => i !== index))}/>
                    )}
                </div>
                <div className="form-item">
                    <label>Openings: </label>
                    <select name="date">
                        <option value="">-- --</option>
                        {openings.map((opening, index) => {
                            const [h, m] = opening.start_time.split(":");
                            let suffix   = "AM";
                            let hours    = parseInt(h);
                            if (hours >= 12) {
                                suffix = "PM";
                                if (hours > 12) {
                                    hours -= 12;
                                }
                            }

                            const textval = dayjs(opening.start_date).format("MMMM DD") + " " + hours + ":" + m + " " + suffix;

                            return <option value={textval} key={index}>
                                {textval}
                            </option>
                        })}
                    </select>
                </div>
                <input type="submit" value="Submit" onClick={submit}/>
            </form>
        </div>
        : formState === 1 ? <h1 className='form-state'>Loading...</h1>
        : formState === 2 && <h1 className='form-state'>Success!</h1>}
    </div>
}

export default ContactComponent;