import { useEffect, useState } from "react";
import { getFlashes } from "../stores/api";
import "./FlashesComponent.scss";
import {ReactComponent as DoubleArrow} from "../assets/img/double-arrow-svgrepo-com.svg";
import FlashIcon from "../Common/FlashIcon";

const FlashesComponent = () => {
    const [flashes, setFlashes]   = useState<any[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        getFlashes().then(setFlashes);
    }, []);

    return <div style={{display: "block"}}>
        <div className="legend">
            <div className="legend-item">
                <DoubleArrow width={50} height={50}/> = minimum size
            </div>
            <div className="legend-item">
                <FlashIcon/> = single use only
            </div>
        </div>
        <div className="flashes-container">
            {!isLoaded && <div className="loading">Loading...</div>}
            <div className="flashes-wrapper">
                {flashes.map((flash, i) => <div className="flash" key={i}>
                    {!flash?.is_repeatable && <FlashIcon/>}
                    <img src={flash?.url} alt="" onLoad={() => setIsLoaded(true)}/>
                    <div className="size">
                        <div className="size-label">{flash?.size}</div>
                        <DoubleArrow width={50} height={50}/>
                    </div>
                </div>)}
            </div>
        </div>
    </div>
};

export default FlashesComponent;