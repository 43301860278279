import React, { useEffect, useRef, useState } from "react";
import "./HomeComponent.scss";
import { Link } from "react-router-dom";
import InstagramPlug from "../Common/InstagramPlug";
import logo from "../assets/img/pidgietatts.png";

const HomeComponent = () => {
    return <div className="home-container">
        <div className="splash-image-container">
            <div className="splash-image"></div>
        </div>
        <div className="center-card">
            <div className="column details-column">
                <img className="home-title" src={logo} alt=""/>
                <Link to={"/contact"} className="button">Book an Appointment</Link>
                <div className="bio">
                    <h3>About Me</h3>
                    <p>Hey there! I'm Addie, calling Utah my home and art my lifelong passion. From cute critters to animals, insects, and botanicals, my tattoos mirror the things that light up my world. I love experimenting with different subjects and styles, weaving between thick bold linework and delicate fine lines. When I'm not in the studio, you'll find me at home with my two cats, a frog, and my amazing partner—I'm happily married and loving every moment of it. Beyond art, my world revolves around video games, fiber arts and music.</p>
                    <p>Ready to embark on a tattoo journey together? Let's create something uniquely you!</p>
                </div>
            </div>
            <div className="column">
                <div className="works-preview">
                    <InstagramPlug/>
                </div>
            </div>
            <div className="bio-mobile">
                <h3>About Me</h3>
                <p>Hey there! I'm Addie, calling Utah my home and art my lifelong passion. From cute critters to animals, insects, and botanicals, my tattoos mirror the things that light up my world. I love experimenting with different subjects and styles, weaving between thick bold linework and delicate fine lines. When I'm not in the studio, you'll find me at home with my two cats, a frog, and my amazing partner—I'm happily married and loving every moment of it. Beyond art, my world revolves around video games, fiber arts and music.</p>
                <p>Ready to embark on a tattoo journey together? Let's create something uniquely you!</p>
            </div>
        </div>
    </div>
}

export default HomeComponent;