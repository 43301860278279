export const apiCall = async(endpoint: string, payload?: any) => {
    return new Promise((resolve, reject) => {
        const xhttp = new XMLHttpRequest();
        xhttp.open(payload ? "POST" : "GET", `https://www.pidgietatts.com/api/${endpoint}.php`);
        // xhttp.setRequestHeader("Content-Type", payload ? "multipart/form-data" : "application/json");
        xhttp.setRequestHeader("Access-Control-Allow-Origin", "*");

        xhttp.onload = (res) => {
            if (xhttp.status === 200) {
                resolve(xhttp.response);
            } else {
                reject("");
            }
        };

        
        if (payload) {
            xhttp.send(payload);
        } else {
            xhttp.send();
        }
    })
}

export const getFlashes = () => {
    return apiCall("get_flashes").then((response: any) => {
        return JSON.parse(response);
    });
}

export const getWorks = () => {
    return apiCall("get_images").then((response: any) => {
        return JSON.parse(response);
    });
}

export const getOpenings = () => {
    return apiCall("get_openings").then((response: any) => {
        return JSON.parse(response);
    });
}

export const addBooking = (booking) => {
    return apiCall("add_booking", booking).then((response: any) => {
        return JSON.parse(response);
    });
}