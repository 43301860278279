import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import "./HeaderComponent.scss";
import {ReactComponent as DrawerIcon} from "../assets/img/drawer-icon.svg";

const HeaderComponent = () => {
    const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
    const [showDrawer, setShowDrawer] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => setWindowSize(window.innerWidth);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize > 767 ?  <div className="header-container">
        <div className="header-column">
            <Link to="/">Addie Saxton</Link>
        </div>
        <div className="header-column">
            <Link to="/flash">Flash</Link>
            <Link to="/works">Works</Link>
            <Link to="/contact">Contact</Link>
        </div>
    </div>
    : <div className="header-container mobile-nav">
        <DrawerIcon onClick={() => setShowDrawer(true)} className="drawer-icon"/>
        <Link to="/">Addie Saxton</Link>
        <div className={`drawer-wrapper ${showDrawer ? "visible" : "hidden"}`}>
            <div className="drawer-nav">
                <div className="drawer-nav-header">
                    <DrawerIcon onClick={() => setShowDrawer(false)} className="drawer-icon"/>
                </div>
                <Link onClick={() => setShowDrawer(false)} to="/">Home</Link>
                <Link onClick={() => setShowDrawer(false)} to="/flash">Flashes</Link>
                <Link onClick={() => setShowDrawer(false)} to="/works">Works</Link>
                <Link onClick={() => setShowDrawer(false)} to="/contact">Contact</Link>
            </div>
            <div className="drawer-background" onClick={() => setShowDrawer(false)}></div>
        </div>
    </div>
};

export default HeaderComponent;