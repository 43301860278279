import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeComponent from './Home/HomeComponent';
import HeaderComponent from './Header/HeaderComponent';
import React from 'react';
import WorksComponent from './Works/WorksComponent';
import ContactComponent from './Contact/ContactComponent';
import "./Index.scss";
import FlashesComponent from './Flashes/FlashesComponent';

const IndexComponent = () => {
    return <div className="index">
        <BrowserRouter>
            <HeaderComponent/>
            <Routes>
                <Route path="/" element={<HomeComponent/>}/>
                <Route path="/flash" element={<FlashesComponent/>}/>
                <Route path="/works" element={<WorksComponent/>}/>
                <Route path="/contact" element={<ContactComponent/>}/>
            </Routes>
        </BrowserRouter>
    </div> 
};

export default IndexComponent;