import React, { useEffect, useState } from 'react';
import { getWorks } from '../stores/api';
import "../Flashes/FlashesComponent.scss";

const WorksComponent = () => {
    const [works, setWorks] = useState<string[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        getWorks().then(setWorks);
    }, []);

    
    return <div className="flashes-container">
        {!isLoaded && <div className="loading">Loading...</div>}
        <div className="flashes-wrapper">
            {works.map((work, i) => <div className="flash" key={i}>
                <img src={work} alt="" onLoad={() => setIsLoaded(true)}/>
            </div>)}
        </div>
    </div>
};

export default WorksComponent;